import { createSlice } from '@reduxjs/toolkit';
import { createPermission } from './permissionThunk';
// ----------------------------------------------------------------------
const initialState = {
    createStatus: 'idle',
    createError: '',
    createPermissionRes: null,
};
const slice = createSlice({
    name: 'permission',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createPermission.pending, (state) => {
            state.createStatus = 'loading';
        })
            .addCase(createPermission.fulfilled, (state, { payload }) => {
            state.createStatus = 'succeeded';
            state.createPermissionRes = payload;
        })
            .addCase(createPermission.rejected, (state, { error }) => {
            state.createStatus = 'failed';
            state.createError = error?.message || 'An error occured';
        });
    },
});
export default slice.reducer;
