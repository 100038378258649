import { createAsyncThunk } from '@reduxjs/toolkit';
import apiKey from '../../../utils/axiosInstance';
export const createPermission = createAsyncThunk('permission/createPermission', async (body, thunkApi) => {
    let data;
    try {
        const response = await apiKey.post(`/permissions`, body);
        data = response;
        if (response.status === 200) {
            return data.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : 'apiError');
    }
});
